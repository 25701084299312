@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

:root {
  /* --color-top-left: #26547C; */
  /* --color-top-right: #EF476F; */
  /* --color-bottom-left: #FFD166; */
  /* --color-bottom-right: #06D6A0; */
  --color-top-left: #F4B9BC;
  --color-top-right: #F4CC99;
  --color-bottom-left: #F5B67B;
  --color-bottom-right: #F4B9BC;
}

#root {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: 
  radial-gradient(
    circle at top left,
    /* rgba(255, 255, 255, 0.5),  */
    var(--color-top-left),
    transparent 50%
  ),
  radial-gradient(
    circle at top right,
    /* rgba(255, 255, 255, 0.5),  */
    var(--color-top-right),
    transparent 50%
  ),
  radial-gradient(
    at bottom left,
    /* rgba(255, 0, 255, 0.5),  */
    var(--color-bottom-left),
    transparent 50%
  ),
  radial-gradient(
    at bottom right,
    /* rgba(255, 100, 100, 0.5),  */
    var(--color-bottom-right),
    transparent 50%
  );
}

.content:before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  /* background-image: url('./static/img/background-image.jpg'); */
  background-size: cover;
  background-position: left -450px bottom 100px;
  width: 100%;
  height: 100%;

  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.content {
  /* this is needed or the background will be offset by a few pixels at the top */
  overflow: auto;
  position: relative;
  margin: auto;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
  .content:before {}
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  .content:before {}
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .content:before {}
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  .content:before {}
}
